@font-face {
  font-family: 'sf-pro-rounded';
  src: url('./SF-Pro-Rounded-Light.otf') format('opentype');
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: 'sf-pro-rounded';
  src: url('./SF-Pro-Rounded-Regular.otf') format('opentype');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'sf-pro-rounded';
  src: url('./SF-Pro-Rounded-Medium.otf') format('opentype');
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: 'sf-pro-rounded';
  src: url('./SF-Pro-Rounded-Semibold.otf') format('opentype');
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: 'sf-pro-rounded';
  src: url('./SF-Pro-Rounded-Bold.otf') format('opentype');
  font-weight: 700;
  font-style: normal;
}
